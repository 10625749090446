.services-container {
  display: flex;
  padding: 0px 60px 0px 136px;
  background-color: #fff;
  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
  .services-left-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .client-says {
      .clients-header {
        display: flex;
        flex-direction: row;
        .client-test {
          display: flex;
          flex-direction: column;
          width: 80%;
          h5.testimonials {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            letter-spacing: 0.06em;
            color: #3dc9a1;
            text-align: left;
          }
          h2.what-client-says {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 42px;
            line-height: 45px;
            color: #208c84;
            text-align: left;
            margin: 16px 0 32px 0;
            @media screen and (max-width: 768px) {
              font-size: 35px;
              line-height: 38px;
            }
          }
          .discription {
            p {
              color: #191919;
              font-weight: 400;
              font-size: 16px;
            }
            a {
              color: #208c84;
              font-size: 16px;
              span {
                svg {
                  width: 7px;
                  margin-left: 4px;
                }
              }
            }
          }
        }
        .client-desc {
          margin-left: -30px;
          width: 80px;
        }
      }
    }
  }
  .services-right-container {
    display: flex;
    padding-right: 60px;
    .client-container {
      .client-review-container {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-top: 3%;
        margin-bottom: 3%;
        padding: 3%;
        background: #ffffff;
        border: 1px solid #3dc9a1;
        border-radius: 30px 30px 30px 0px;
        transition: all ease 0.3s;
        &:hover {
          background-color: #3dc9a1;
        }
        .client-review {
          padding: 3%;
          .review-msg {
            font-size: 13px;
            font-style: italic;
            margin-bottom: 8px;
            line-height: 20px;
          }
        }
        .client-personal {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .client-name {
            display: flex;
            align-items: center;
            gap: 12px;
            .images-client {
              width: 48px;
              height: 48px;
            }
            .name-client {
              h5 {
                font-size: 16px;
                font-weight: 700;
                margin: 0;
              }
              p {
                font-size: 14px;
                font-weight: 400;
                margin: 0;
                line-height: 16px;
              }
            }
          }
          .rating-section {
            .star-rate {
              border: 1px solid #3dc9a1;
              border-radius: 25px;
              display: inline-flex;
              padding: 2px 4px;
              svg {
                width: 22px;
                color: #ffac33;
              }
            }
            p {
              text-align: right;
              margin: 0;
              line-height: 14px;
              font-size: 11px;
            }
          }
        }
      }
    }
    .first-column {
      width: 100%;
    }

    .client-container {
      margin-left: 3%;
      margin-right: 3%;
    }
  }
}
